import React, { SyntheticEvent, useCallback, useState } from "react";

import useMeetingState from "../../hooks/meetings/useMeetingState";
import useAttendMeeting from "../../hooks/meetings/useAttendMeeting";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import {
  MeetingAttendConfirmed,
  MeetingLeaveConfirmed,
  MeetingState,
  ParticipantAttendanceType,
  ParticipantDelegationType,
  UserRoles,
} from "../../enums";
import Button from "../common/Button";

import UpdateDialog from "../common/dialog/UpdateDialog";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import AlertDialog from "../common/dialog/AlertDialog";
import LoginIcon from "../icons/LoginIcon";
import LogoutIcon from "../icons/LogoutIcon";

import "../../styles/components/meetings/MeetingAttend.scss";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import useHandleMeeting from "../../hooks/meetings/useHandleMeeting";
import MeetingWatchInformation from "./MeetingWatchInformation";
import DialogDescriptionDelegateBeforeLeaving from "./DialogDescriptionDelegateBeforeLeaving";
import { Participant } from "../../types";
import { EMPLOYEE_PARTICIPANT } from "../../lib/meeting";
import MeetingInformationForm from "./MeetingInformationForm";

export default function MeetingAttend({ title, id }: Props): JSX.Element {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const meetingIdToAttend = id ? id : "";
  const meetingState = useMeetingState(id);
  const customerRoles = useCustomerRoles();
  const isEmployee = customerRoles.includes(UserRoles.Employee);
  const [attendConfirmed, setAttendConfirmed] = useState(
    MeetingAttendConfirmed.IsToConfirm,
  );
  const [leaveConfirmed, setLeaveConfirmed] = useState(
    MeetingLeaveConfirmed.IsToConfirm,
  );
  const { handleLeave, isAttending, isLeaving, data } = useAttendMeeting(
    meetingState,
    customerRoles,
    id,
  );
  const [delegateParticipant, setDelegateParticipant] = useState<
    Participant | undefined
  >(undefined);

  const meetingAttend = data?.attend;
  const meetingWatch = data?.watch;

  const {
    handleJoinMeeting,
    isParticipationConfirmed,
    setParticipationConfirmed,
  } = useHandleMeeting(id, attendConfirmed);

  const handleCancelConfirmation = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setParticipationConfirmed(MeetingAttendConfirmed.IsToConfirm);
      setAttendConfirmed(MeetingAttendConfirmed.IsToConfirm);
      setLeaveConfirmed(MeetingLeaveConfirmed.IsToConfirm);
    },
    [setAttendConfirmed],
  );

  const handleLeaveMeeting = useCallback(
    async (e: SyntheticEvent<HTMLButtonElement>) => {
      if (isEmployee) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      let delegationType = ParticipantDelegationType.None;
      if (delegateParticipant?.id === EMPLOYEE_PARTICIPANT.id) {
        delegationType =
          ParticipantDelegationType.VotingEligibilityDelegatedToEmployee;
      }
      if (
        delegateParticipant &&
        delegateParticipant?.id !== EMPLOYEE_PARTICIPANT.id
      ) {
        delegationType =
          ParticipantDelegationType.VotingEligibilityDelegatedToParticipant;
      }
      const attendance =
        e.currentTarget.id === "dialog-actions-confirm-1"
          ? ParticipantAttendanceType.Abscent
          : ParticipantAttendanceType.Locally;
      switch (leaveConfirmed) {
        case MeetingLeaveConfirmed.IsToConfirm:
          setLeaveConfirmed(MeetingLeaveConfirmed.InConfirmation);
          break;
        case MeetingLeaveConfirmed.InConfirmation:
          setLeaveConfirmed(MeetingLeaveConfirmed.IsToConfirm);
          handleLeave(attendance, delegationType, delegateParticipant?.id);
          navigate("../versammlungen");
          break;
        default:
          handleLeave(attendance);
      }
    },
    [
      customerRoles,
      leaveConfirmed,
      setLeaveConfirmed,
      handleLeave,
      delegateParticipant,
    ],
  );

  const className = classNames("etv-meeting-attend", {
    "meeting-info-page": pathname.includes(meetingIdToAttend),
  });

  return (
    <div className={className} data-testid="etv-meeting-attend">
      {meetingState === MeetingState.Running && meetingAttend && !isLeaving && (
        <Button
          id="to-confirm-leave"
          label="Verlassen"
          grey
          onClick={handleLeaveMeeting}
          leadingIcon={LogoutIcon}
        />
      )}
      {meetingState === MeetingState.Running &&
        !isAttending &&
        !meetingAttend && (
          <Button
            label="Teilnehmen"
            lightblue
            onClick={handleJoinMeeting}
            leadingIcon={LoginIcon}
          />
        )}
      {isAttending && (
        <UpdateDialog
          description={`Teilnahme an ${title} wird gestartet`}
          title="Teilnahme"
        />
      )}
      {isLeaving && (
        <UpdateDialog
          description={`Teilnahme an ${title} wird beendet`}
          title="Teilnahme"
        />
      )}
      <MeetingInformationForm
        isEmployee={isEmployee}
        isParticipationConfirmed={isParticipationConfirmed}
        meetingState={meetingState}
        meetingWatch={meetingWatch}
        handleJoinMeeting={handleJoinMeeting}
        title={title}
        meetingIdToAttend={meetingIdToAttend}
        handleCancelConfirmation={handleCancelConfirmation}
      />
      {!isEmployee &&
        isParticipationConfirmed === MeetingAttendConfirmed.InConfirmation &&
        meetingState === MeetingState.Running &&
        meetingWatch && (
          <AlertDialog
            description={
              <MeetingWatchInformation meetingId={meetingIdToAttend} />
            }
            title="Teilnahme an der Versammlung nicht möglich"
            onConfirm={(e) => {
              handleJoinMeeting(e as SyntheticEvent<HTMLButtonElement>);
            }}
            confirmLabel="OK"
          />
        )}
      {!isEmployee &&
        leaveConfirmed === MeetingLeaveConfirmed.InConfirmation && (
          <ConfirmDialog
            description={
              <DialogDescriptionDelegateBeforeLeaving
                meetingIdToAttend={meetingIdToAttend}
                delegateParticipant={delegateParticipant}
                setDelegateParticipant={setDelegateParticipant}
              />
            }
            title="Vollmacht erteilen vor verlassen"
            onConfirm={handleLeaveMeeting}
            confirmLabel={
              !delegateParticipant
                ? "Versammlung verlassen"
                : "Vollmacht erteilen und Versammlung verlassen"
            }
            onCancel={handleCancelConfirmation}
          />
        )}
    </div>
  );
}

type Props = {
  title: string;
  id?: string;
};
