import React, { ReactElement, useRef } from "react";
import { Field, useFormState } from "react-final-form";
import TextInput from "../common/form/TextInput";
import "../../styles/components/meetings/EtvPinChangeDialogDescription.scss";
import { validatePinForEmployee } from "../../lib/formValidate";
import InfoText from "../userSettings/InfoText";
import { useAutoFocusOnPin } from "../../hooks/meetings/useAutoFocusOnPin";

export default function EtvPinChangeDialogDescription({
  deleteMeetingPinCodeError,
  postMeetingPinCodeError,
}: Props): ReactElement {
  const { values } = useFormState();
  const refElement = useRef<HTMLDivElement>(null);
  const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  useAutoFocusOnPin(refElement, values.pinValue);
  return (
    <section className="etv-pin-dialog-wrapper">
      <div
        ref={refElement}
        tabIndex={-1}
        style={{ position: "absolute", opacity: 0 }}
        data-testid={"pin-focus-element"}
      ></div>
      <div className="etv-pin-dialog-description">
        Geben Sie unten die neue PIN ein. Sobald bestätigt, können Teilnehmer
        nur mit der neuen PIN der Versammlung beitreten.
      </div>

      <div className="dialog-description-fields-wrapper">
        <div className="etv-pin-wrapper">
          <Field
            name="pinValue"
            component={TextInput}
            type="number"
            maxLength={4}
            validate={validatePinForEmployee}
            parse={(value) => value?.slice(0, 4)}
            role="input"
            onInput={handleOnInput}
            className="etv-pin-input"
          />
          <div className="quad-underline"></div>
          <span>Bitte eine 4-stelligen numerische PIN eingeben.</span>
        </div>
      </div>
      {(postMeetingPinCodeError || deleteMeetingPinCodeError) && (
        <InfoText
          isError={true}
          textError={
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
          }
        />
      )}
    </section>
  );
}

type Props = {
  postMeetingPinCodeError: boolean;
  deleteMeetingPinCodeError: boolean;
};
