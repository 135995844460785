import { useEffect, RefObject } from "react";

export const useAutoFocusOnPin = (
  ref: RefObject<HTMLElement>,
  pinValue: string,
) => {
  useEffect(() => {
    if (ref.current && pinValue.length === 4) {
      ref.current.focus();
    }
  }, [pinValue, ref]);
};
