import React, { SyntheticEvent, useCallback } from "react";
import { Form } from "react-final-form";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import EtvPinChangeDialogDescription from "./EtvPinChangeDialogDescription";
import {
  useDeleteMeetingPinCodeMutation,
  usePostMeetingPinCodeMutation,
} from "../../api/meetingApi";
import { useParams } from "react-router-dom";

export default function EtvPinChangeDialog({
  onConfirm,
  onCancel,
  meetingPinCode,
}: Props) {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();
  const [postMeetingPinCode, { isError: postMeetingPinCodeError }] =
    usePostMeetingPinCodeMutation();
  const [deleteMeetingPinCode, { isError: deleteMeetingPinCodeError }] =
    useDeleteMeetingPinCodeMutation();

  const onSubmit = useCallback(async (values: { pinValue: string }) => {
    let resultInfo;

    if (values.pinValue) {
      resultInfo = await postMeetingPinCode({
        customerToken,
        meetingId,
        facilityObjectId,
        pinCode: values.pinValue,
      });
    }
    if (!values.pinValue) {
      resultInfo = await deleteMeetingPinCode({
        customerToken,
        meetingId,
        facilityObjectId,
      });
    }

    if (resultInfo && "error" in resultInfo) {
      return;
    }

    onConfirm();
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        pinValue: meetingPinCode ? meetingPinCode : " ",
      }}
      render={({ handleSubmit, dirty }) => {
        return (
          <ConfirmDialog
            confirmLabel="PIN ändern"
            cancelLabel="Abbrechen"
            title="Teilnehmer-PIN ändern"
            description={
              <EtvPinChangeDialogDescription
                postMeetingPinCodeError={postMeetingPinCodeError}
                deleteMeetingPinCodeError={deleteMeetingPinCodeError}
              />
            }
            onConfirm={handleSubmit}
            onCancel={onCancel}
            confirmDisabled={!dirty}
          />
        );
      }}
    />
  );
}

type Props = {
  onConfirm: () => void;
  onCancel?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  meetingPinCode?: string;
};
