import React, { useRef } from "react";
import { Field, useFormState } from "react-final-form";
import TextInput from "../common/form/TextInput";
import CheckboxField from "../common/form/CheckboxField";
import "../../styles/components/meetings/MeetingConfirmStartDialogDescription.scss";
import { validatePinNumber } from "../../lib/formValidate";
import { useAutoFocusOnPin } from "../../hooks/meetings/useAutoFocusOnPin";
import InfoText from "../userSettings/InfoText";

export default function MeetingConfirmStartDialogDescription({
  postMeetingPinCodeError,
}: Props): React.ReactElement {
  const { values } = useFormState();
  const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const refElement = useRef<HTMLDivElement>(null);
  useAutoFocusOnPin(refElement, values.pinValue);
  return (
    <section className="start-dialog-description-wrapper">
      <div
        ref={refElement}
        tabIndex={-1}
        style={{ position: "absolute", opacity: 0 }}
        data-testid={"pin-focus-element"}
      ></div>
      <div className="start-dialog-description">
        <ul className="dialog-description-list">
          <li>
            Bitte stellen Sie sicher, dass Sie vor Ort über eine stabile
            Internetverbindung verfügen.
          </li>
          <li>
            Ihr Server mit DOMUS ERP Conventus muss in Betrieb sein, damit die
            Daten zugänglich sind.
          </li>
        </ul>
      </div>
      <div className="dialog-description-fields-wrapper">
        <Field
          name="isPin"
          component={CheckboxField}
          required
          type="checkbox"
          label="Teilnahme-PIN hinzufügen"
          role="checkbox"
        />
        {values.isPin && (
          <div className="etv-pin-wrapper">
            <Field
              name="pinValue"
              component={TextInput}
              required
              type="number"
              maxLength={4}
              validate={validatePinNumber}
              parse={(value) => value?.slice(0, 4)}
              role="input"
              onInput={handleOnInput}
              className="etv-pin-input"
            />
            <div className="quad-underline"></div>
            <span>
              Bitte eine 4-stelligen numerische PIN eigeben. Diese kann später
              Versammlung geändert werden.
            </span>
          </div>
        )}
        {postMeetingPinCodeError && (
          <InfoText
            isError={true}
            textError="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
          />
        )}
      </div>
    </section>
  );
}

type Props = {
  postMeetingPinCodeError: boolean;
};
