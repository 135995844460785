import React, { SyntheticEvent, useCallback, useMemo } from "react";
import { Form } from "react-final-form";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import MeetingConfirmStartDialogDescription from "./MeetingConfirmStartDialogDescription";
import { useParams } from "react-router-dom";
import {
  useGetMeetingPinCodeQuery,
  usePostMeetingPinCodeMutation,
} from "../../api/meetingApi";
export default function MeetingStartDialog({
  onCancel,
  handleStartMeeting,
  meetingId = "",
}: Props) {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const { data: meetingPinCode } = useGetMeetingPinCodeQuery({
    customerToken,
    meetingId,
    facilityObjectId,
  });
  const [postMeetingPinCode, { isError: postMeetingPinCodeError }] =
    usePostMeetingPinCodeMutation();

  const onSubmit = useCallback(async (values: Record<string, string>) => {
    let resultInfo;
    if (values.pinValue && values.isPin) {
      resultInfo = await postMeetingPinCode({
        customerToken,
        meetingId,
        facilityObjectId,
        pinCode: values.pinValue,
      });
    }

    if (resultInfo && "error" in resultInfo) {
      return;
    }
    await handleStartMeeting();
  }, []);

  const generateRandomPin = useMemo(() => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        isPin: false,
        pinValue: meetingPinCode?.pinCode
          ? meetingPinCode.pinCode
          : generateRandomPin,
      }}
      keepDirtyOnReinitialize={true}
      render={({ handleSubmit }) => (
        <ConfirmDialog
          confirmLabel="Eigentümerversammlung eröffnen"
          cancelLabel="Abbrechen"
          title="Eigentümerversammlung starten"
          description={
            <MeetingConfirmStartDialogDescription
              postMeetingPinCodeError={postMeetingPinCodeError}
            />
          }
          onConfirm={handleSubmit}
          onCancel={onCancel}
          showCloseButton
        />
      )}
    />
  );
}

type Props = {
  handleStartMeeting: (e?: SyntheticEvent) => void;
  onCancel?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  meetingId?: string;
};
