import React from "react";

import "../../styles/components/meetings/MeetingAttendInformation.scss";
import EtvIcon from "../icons/EtvIcon";

export default function MeetingAttendInformation({
  title,
}: Props): JSX.Element {
  return (
    <div className="etv-meeting-attend-information-wrapper">
      <EtvIcon className="etv-icon" />
      <span className="etv-meeting-attend-information">
        Herzlich willkommen zur Eigentümerversammlung <strong>{title}</strong>.
      </span>
      <span className="etv-meeting-attend-information">
        Sie können nun beitreten.
      </span>
      <span className="etv-meeting-attend-information">
        Bitte stellen Sie sicher, dass Sie eine stabile Internetverbindung
        haben.
      </span>
      <span className="etv-meeting-attend-information mobile">
        Mit Klick auf den Button <strong>Jetzt beitreten</strong> bestätigen
        Sie, dass keine Fremden (die nicht Eigentümer im Haus sind) in Ihrer
        Nähe die Versammlung sehen / hören können.
      </span>
    </div>
  );
}

type Props = {
  title: string;
};
