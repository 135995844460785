import React, { ReactElement, useMemo } from "react";
import { StakeholdersInfo } from "../../../types";
import { useGetOnlineIdentitiesQuery } from "../../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import useTableSort from "../../../hooks/common/useTableSort";
import TableSortHeader from "../../common/TableSortHeader";
import useSortOnlineIdentity from "../../../hooks/useSortOnlineIdentity";
import OnlineIdentityItem from "./OnlineIdentityItem";
import "../../../styles/components/management/objectSettings/PropertyStakeholdersInfoListItemUsersAndCode.scss";
import RegCodeItem from "./RegCodeItem";
import InfoText from "../../userSettings/InfoText";
import CrossIcon from "../../icons/CrossIcon";
import useCreateNewCode from "../../../hooks/useCreateNewCode";

export default function StakeholdersInfoListItemUsersAndCode({
  isActiveStakeholder,
}: Props): ReactElement {
  const { customerToken = "" } = useParams();
  const { data: onlineIdentity } = useGetOnlineIdentitiesQuery({
    customerToken,
    tenantId: isActiveStakeholder?.addressId,
  });

  const onlineIdentityWithUserSid = useMemo(() => {
    return onlineIdentity?.filter((item) => item.userSid !== null);
  }, [onlineIdentity]);

  const onlineIdentityWithoutUserSid = useMemo(() => {
    return onlineIdentity?.filter((item) => item.userSid === null);
  }, [onlineIdentity]);

  const { sort, handleSort } = useTableSort(
    {
      sortBy: "name",
      sortDirection: "ascending",
    },
    "facility-objects-tenants-list",
  );

  const sortOptions = [
    { sortBy: "name", label: "Registrierte Benutzer" },
    { sortBy: "e-mail", label: "E-Mail-Adresse" },
    { sortBy: "account", label: "Konto" },
    { sortBy: "status", label: "Status" },
    { sortBy: "reg-codes", label: "Reg. Codes" },
    { sortBy: "code-created", label: "Code erstellt" },
  ];

  const { sort: sortRegCodes, handleSort: handleSortRegCodes } = useTableSort(
    {
      sortBy: "reg-codes-without-user-sid",
      sortDirection: "ascending",
    },
    "reg-codes-list",
  );

  const sortOptionsRegCodes = [
    { sortBy: "reg-codes-without-user-sid", label: "Reg. Codes" },
    { sortBy: "code-created", label: "Code erstellt" },
  ];

  const sortedOnlineIdentity = useSortOnlineIdentity(
    onlineIdentityWithUserSid ? onlineIdentityWithUserSid : [],
    sort,
  );

  const sortedOnlineIdentityWithoutUserSid = useSortOnlineIdentity(
    onlineIdentityWithoutUserSid ? onlineIdentityWithoutUserSid : [],
    sortRegCodes,
  );

  const allRegCodeNull = useMemo(() => {
    return onlineIdentityWithoutUserSid?.every((item) => item.regCode === null);
  }, [sortedOnlineIdentityWithoutUserSid]);

  const { handleCreateNewCode } = useCreateNewCode(isActiveStakeholder);

  return (
    <section className="stakeholders-wrapper">
      <div className="stakeholders-container">
        <span className="stakeholders-container-title">
          {isActiveStakeholder.firstName} {isActiveStakeholder.lastName}
        </span>
        <div className="stakeholders-info">
          {sortedOnlineIdentity && sortedOnlineIdentity.length > 0 && (
            <div className="sort-header">
              <TableSortHeader
                handleSort={handleSort}
                sortOptions={sortOptions}
                sort={sort}
              />
            </div>
          )}

          <div className="stakeholders-info-list-body" role="row">
            {sortedOnlineIdentity &&
              sortedOnlineIdentity.length > 0 &&
              sortedOnlineIdentity
                .filter(
                  (item) =>
                    item.userSid !== null && item.dcsAccountDeleted !== true,
                )
                .map((identity) => (
                  <OnlineIdentityItem
                    key={identity.oid}
                    onlineIdentityInfo={identity}
                    isActivePropertyStakeholder={isActiveStakeholder}
                  />
                ))}

            {sortedOnlineIdentity && sortedOnlineIdentity.length === 0 && (
              <InfoText
                isInfo={true}
                textInfo={"Es hat sich noch kein Nutzer registriert."}
              />
            )}
            <div className="reg-codes-wrapper">
              <span className="reg-codes-list-title">
                Freie Registrierungs-Codes
              </span>
              {(sortedOnlineIdentityWithoutUserSid.length === 0 ||
                allRegCodeNull) && (
                <InfoText
                  isInfo={true}
                  textInfo={
                    "Derzeit sind keine freien Registrierungscodes vorhanden."
                  }
                />
              )}
              {sortedOnlineIdentityWithoutUserSid.length !== 0 &&
                !allRegCodeNull && (
                  <div className="code-list-wrapper">
                    <div className="sort-header">
                      <TableSortHeader
                        handleSort={handleSortRegCodes}
                        sortOptions={sortOptionsRegCodes}
                        sort={sortRegCodes}
                      />
                    </div>

                    {sortedOnlineIdentityWithoutUserSid?.map(
                      (item) =>
                        item.regCode !== null && (
                          <RegCodeItem
                            key={item.regCode}
                            regCode={item.regCode}
                            createDate={item.createDateUTC}
                          />
                        ),
                    )}
                  </div>
                )}

              <div
                className={"add-new-code-wrapper"}
                onClick={handleCreateNewCode}
              >
                <CrossIcon />
                <span> Neuen Code austellen</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
type Props = {
  isActiveStakeholder: StakeholdersInfo;
};
