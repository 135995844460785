import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { MeetingAttendConfirmed, MeetingState } from "../../enums";
import "../../styles/components/meetings/MeetingListItem.scss";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import { Form } from "react-final-form";
import MeetingAttendInformationWithPinCode from "./MeetingAttendInformationWithPinCode";

export default function MeetingInformationForm({
  isEmployee,
  isParticipationConfirmed,
  meetingState,
  meetingWatch,
  handleJoinMeeting,
  title,
  meetingIdToAttend = "",
  handleCancelConfirmation,
}: Props): ReactElement {
  const [isConfirmDisabled, setConfirmDisabled] = useState(true);
  const handleConfirmDisabled = useCallback(() => {
    setConfirmDisabled(true);
  }, []);

  const handleConfirmNotDisabled = useCallback(() => {
    setConfirmDisabled(false);
  }, []);

  const [isMeetingPinCodeRequired, setMeetingPinCodeRequired] = useState(false);
  return (
    <div>
      {!isEmployee &&
        isParticipationConfirmed === MeetingAttendConfirmed.InConfirmation &&
        meetingState === MeetingState.Running &&
        !meetingWatch && (
          <Form
            onSubmit={(values) => {
              handleJoinMeeting(undefined, values.pinValue);
            }}
            initialValues={{
              pinValue: "",
            }}
            render={({ handleSubmit, values }) => {
              const confirmDisabled =
                (isConfirmDisabled && isMeetingPinCodeRequired) ||
                (values.pinValue.length !== 4 && isMeetingPinCodeRequired);
              return (
                <ConfirmDialog
                  description={
                    <MeetingAttendInformationWithPinCode
                      title={title}
                      meetingIdToAttend={meetingIdToAttend}
                      setConfirmDisabled={handleConfirmDisabled}
                      handleConfirmNotDisabled={handleConfirmNotDisabled}
                      setMeetingPinCodeRequired={setMeetingPinCodeRequired}
                    />
                  }
                  title="Eigentümerversammlung beitreten"
                  onConfirm={handleSubmit}
                  confirmLabel="Jetzt beitreten"
                  onCancel={handleCancelConfirmation}
                  showCloseButton
                  confirmDisabled={confirmDisabled}
                />
              );
            }}
          />
        )}
    </div>
  );
}

type Props = {
  isEmployee: boolean;
  isParticipationConfirmed?: MeetingAttendConfirmed;
  meetingState: MeetingState;
  meetingWatch?: boolean;
  handleJoinMeeting: (
    e?:
      | React.SyntheticEvent<HTMLButtonElement, Event>
      | React.SyntheticEvent<HTMLDivElement, Event>
      | undefined,
    pinValue?: string | undefined,
  ) => void;
  title: string;
  meetingIdToAttend?: string;
  handleCancelConfirmation: (e: SyntheticEvent<HTMLButtonElement>) => void;
};
