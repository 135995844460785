import React, {
  Fragment,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import Button from "../common/Button";
import useUpdateMeetingState from "../../hooks/meetings/useUpdateMeetingState";
import { SynchronisationMethod } from "../../enums";
import { useSynchronisation } from "../../hooks/useSynchronisation";
import AlertDialog from "../common/dialog/AlertDialog";
import MeetingStartDialog from "./MeetingStartDialog";

export default function MeetingConfirmStartDialog({
  meetingId,
  disabled,
}: Props): JSX.Element {
  const { startMeeting, isSuccess, isError } = useUpdateMeetingState(meetingId);
  const [isOpenToConfirm, setOpenToConfirm] = useState(false);
  const [isErrorToConfirm, setIsErrorToConfirm] = useState(false);
  const { syncMeeting } = useSynchronisation();
  const descriptionDialog = (
    <ul className="dialog-description-list">
      <li>
        Bitte stellen Sie sicher, dass Sie vor Ort über eine stabile
        Internetverbindung verfügen.
      </li>
      <li>
        Ihr Server mit DOMUS ERP Conventus muss in Betrieb sein, damit die Daten
        zugänglich sind.
      </li>
    </ul>
  );

  const handleConfirmOpenMeeting = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setOpenToConfirm(!isOpenToConfirm);
    },
    [setOpenToConfirm, isOpenToConfirm],
  );

  const handleErrorOpenMeeting = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setOpenToConfirm(false);
      setIsErrorToConfirm(false);
    },
    [setOpenToConfirm, isOpenToConfirm],
  );

  const handleStartMeeting = useCallback(
    (e?: SyntheticEvent) => {
      setOpenToConfirm(false);
      startMeeting(e);
    },
    [setOpenToConfirm],
  );

  useEffect(() => {
    if (isError) {
      setIsErrorToConfirm(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && meetingId) {
      syncMeeting(SynchronisationMethod.MeetingStateChanged, meetingId);
    }
  }, [isSuccess, meetingId]);

  return (
    <Fragment>
      <Button
        label="Versammlung starten"
        lightblue
        onClick={handleConfirmOpenMeeting}
        disabled={disabled}
      />
      {isOpenToConfirm && (
        <MeetingStartDialog
          handleStartMeeting={handleStartMeeting}
          onCancel={handleConfirmOpenMeeting}
          meetingId={meetingId}
        />
      )}
      {isError && isErrorToConfirm && (
        <AlertDialog
          confirmLabel="OK"
          title="Versammlung konnte nicht gestartet werden"
          description={descriptionDialog}
          onConfirm={handleErrorOpenMeeting}
        />
      )}
    </Fragment>
  );
}

type Props = {
  meetingId?: string;
  disabled?: boolean;
};
