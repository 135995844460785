import { IconProps } from "../../types";
import { ReactElement } from "react";

export default function EtvIcon({
  width = 650,
  height = 350,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 420 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(0 .224)" fillRule="nonzero" fill="none">
        <path
          d="M240.726 163.776H133.492a3.28 3.28 0 0 1-3.274-3.279V84.59a3.28 3.28 0 0 1 3.274-3.278h107.234A3.28 3.28 0 0 1 244 84.59v75.907a3.28 3.28 0 0 1-3.274 3.279z"
          fill="#E6E6E6"
        />
        <path
          d="M236.262 85.782h-98.304a3.282 3.282 0 0 0-3.276 3.28v66.963a3.282 3.282 0 0 0 3.276 3.28h98.304a3.28 3.28 0 0 0 3.273-3.28V89.062a3.28 3.28 0 0 0-3.273-3.28z"
          fill="#FFF"
        />
        <ellipse
          fill="#9E616A"
          transform="rotate(-77.07 183.202 114.658)"
          cx="183.202"
          cy="114.658"
          rx="13.218"
          ry="13.202"
        />
        <path
          d="M221.168 146.858a12.971 12.971 0 0 0-9.056-9.773l-.01-.006a5.647 5.647 0 0 0-1.354-.465l-15.864-3.25-3.179-3.416-13.524.865-1.412 3.929-15.536 6.914.258-.181c-.666.267-1.313.578-1.938.93l-.079.035a5.03 5.03 0 0 0-1.235.789c-3.95 2.745-6.56 7.153-6.657 12.067l-.41 4.01h72.634l-2.638-12.448z"
          fill="#00BEF2"
        />
        <path
          d="M197.649 107.572c-1.08-3.38-4.243-6.234-7.786-6.143.276-.844-.195-1.789-.901-2.326-.706-.536-1.598-.751-2.466-.932-1.793-.374-3.662-.66-5.445-.238-1.782.421-3.472 1.702-3.873 3.492-1.624-1.524-4.626-1.103-5.769.81-.588.984-.671 2.307-.036 3.26.632.949 1.828 1.376 2.964 1.43 1.137.053 2.262-.202 3.394-.321 2.704-.285 5.559.26 7.744 1.88 2.186 1.618 3.582 4.393 3.193 7.087.874-.559 2.133-.17 2.743.669.61.84.658 1.986.374 2.985-.283.998-.858 1.884-1.425 2.753l-3.134 4.8.428.286c1.576.665 3.28-.579 4.427-1.849a25.61 25.61 0 0 0 4.609-7.262c1.37-3.273 2.04-7 .959-10.38z"
          fill="#2F2E41"
        />
        <path
          d="M140.703 104.997H4.169A4.176 4.176 0 0 1 0 100.822V4.175A4.176 4.176 0 0 1 4.169 0h136.534a4.176 4.176 0 0 1 4.169 4.175v96.647a4.176 4.176 0 0 1-4.17 4.175z"
          fill="#E6E6E6"
        />
        <path
          d="M135.019 5.691H9.855a4.179 4.179 0 0 0-4.171 4.177v85.26a4.179 4.179 0 0 0 4.17 4.177H135.02a4.176 4.176 0 0 0 4.167-4.177V9.868a4.176 4.176 0 0 0-4.167-4.177z"
          fill="#FFF"
        />
        <path
          d="m90.446 45.704-.231.416c2.055-5.07.186-11.32-3.48-15.143-3.665-3.824-8.76-5.572-13.778-6.265-3.725-.515-7.591-.5-11.133.866-5.129 1.979-9.048 6.559-12.32 11.324-1.496 2.179-2.967 4.663-2.818 7.387.138 2.528 1.637 4.673 2.864 6.818 1.227 2.144 2.252 4.805 1.37 7.146-.524 1.394-1.633 2.399-2.533 3.54-.9 1.141-1.64 2.683-1.206 4.115.352 1.158 1.41 1.92 2.498 2.174 1.088.254 2.217.088 3.319-.079l28.6-4.327a12.19 12.19 0 0 0 9.112-6.672 12.22 12.22 0 0 0-.264-11.3z"
          fill="#2F2E41"
        />
        <ellipse
          fill="#FFB9B9"
          cx="69.172"
          cy="49.474"
          rx="13.983"
          ry="14.003"
        />
        <path
          d="M104.05 81.172a12.016 12.016 0 0 0-10.2-7.014l-3.897-.236.01.04v.001l-9.908-5.968c.244.28-16.114.865-16.114.865l-6.07 5.243-2.545.154a12.015 12.015 0 0 0-10.2 7.015L36.86 99.404h16.475l.382-1.647c.416.507.82 1.023 1.21 1.548h57.389l-8.266-18.133z"
          fill="#00BEF2"
        />
        <path
          d="M82.436 36.478c-.361-1.912-2.309-2.787-3.978-3.21a38.26 38.26 0 0 0-14.82-.816c-3.606.515-7.297 1.65-9.914 4.469-2.04 2.198-3.275 5.347-3.37 8.598-.057 1.895.282 3.866 1.223 5.462.941 1.596 2.546 2.753 4.203 2.713l.168.584c7.02-.98 13.542-5.354 17.649-11.838a15.17 15.17 0 0 1-4.425 7.158c4.74-1.9 8.86-5.079 11.901-9.185.83-1.104 1.637-2.48 1.363-3.935z"
          fill="#2F2E41"
        />
      </g>
    </svg>
  );
}
