import "../../styles/components/meetings/MeetingAttendInformation.scss";
import { Field, useFormState } from "react-final-form";
import TextInput from "../common/form/TextInput";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetMeetingQuery,
  useValidateMeetingPinCodeMutation,
} from "../../api/meetingApi";
import MeetingAttendInformation from "./MeetingAttendInformation";
import { validatePinNumber } from "../../lib/formValidate";
import { useAutoFocusOnPin } from "../../hooks/meetings/useAutoFocusOnPin";
import LoadingSpinner from "../icons/LoadingSpinner";

export default function MeetingAttendInformationWithPinCode({
  title,
  meetingIdToAttend = "",
  setConfirmDisabled,
  handleConfirmNotDisabled,
  setMeetingPinCodeRequired,
}: Props): JSX.Element {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();
  const [validateMeetingPinCode] = useValidateMeetingPinCodeMutation();
  const { values } = useFormState();
  const [isErrorText, setErrorText] = useState(false);
  const refElement = useRef<HTMLDivElement>(null);

  const validateMeetingPin = useCallback(async () => {
    setConfirmDisabled && setConfirmDisabled();
    if (values.pinValue.length === 4) {
      const result = await validateMeetingPinCode({
        customerToken,
        facilityObjectId,
        meetingId: meetingIdToAttend ? meetingIdToAttend : meetingId,
        pinCode: values.pinValue,
      });

      if ("error" in result) {
        setErrorText(true);
        setConfirmDisabled && setConfirmDisabled();
      } else {
        setErrorText(false);
        handleConfirmNotDisabled && handleConfirmNotDisabled();
      }
    }
  }, [values, meetingIdToAttend]);

  const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    if (e.target.value.length >= 4) {
      e.preventDefault();
    }
  };
  useAutoFocusOnPin(refElement, values.pinValue);

  const meetingIdInfo = meetingId ? meetingId : meetingIdToAttend;
  const shouldFetch = facilityObjectId && customerToken && meetingIdInfo;

  const {
    data: meeting,
    refetch,
    isFetching,
  } = useGetMeetingQuery(
    { facilityObjectId, customerToken, meetingId: meetingIdInfo },
    { skip: !shouldFetch },
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (meeting) {
      setMeetingPinCodeRequired(meeting.isPinCodeRequired);
    }
  }, [meeting?.isPinCodeRequired]);

  return (
    <div className="etv-meeting-attend-wrapper">
      <div
        ref={refElement}
        tabIndex={-1}
        style={{ position: "absolute", opacity: 0 }}
        data-testid={"pin-focus-element"}
      ></div>
      <MeetingAttendInformation title={title} />
      {isFetching && <LoadingSpinner />}
      {meeting?.isPinCodeRequired && !isFetching && (
        <div className="form-fields-wrapper">
          <div className="etv-pin-wrapper">
            <span>Teilnehmer-PIN:</span>
            <Field
              name="pinValue"
              component={TextInput}
              type="number"
              validate={validatePinNumber}
              parse={(value) => value?.slice(0, 4)}
              onBlur={validateMeetingPin}
              onInput={handleOnInput}
              role={"input"}
              maxLength={4}
              className="etv-pin-input"
            />
          </div>
          {isErrorText && (
            <span className="error-text">
              Die eingegebene PIN ist ungültig. Bitte versuchen Sie es erneut.
            </span>
          )}
        </div>
      )}
    </div>
  );
}

type Props = {
  title: string;
  meetingIdToAttend?: string;
  setConfirmDisabled?: () => void;
  handleConfirmNotDisabled?: () => void;
  setMeetingPinCodeRequired: React.Dispatch<React.SetStateAction<boolean>>;
};
