import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  MeetingEventLog,
  Meeting,
  MeetingAcceptInvitationRequest,
  MeetingDataIntegrity,
  MeetingRequest,
  MeetingsRequest,
  MeetingStateRequest,
  MyInvitation,
  ParticipantsRequest,
  MeetingVoteInstructionsRequest,
  ParticipantsWithVoteInstructions,
  ParticipantVoteDirective,
  MeetingPinCodeRequest,
} from "../types";
import { MEETING_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import moment from "moment";

export const meetingApi = createApi({
  reducerPath: MEETING_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: [
    "Meetings",
    "Invitation",
    "VoteInstructions",
    "ParticipantsWithVoteInstructions",
    "MeetingPinCode",
  ],
  endpoints: (build) => ({
    getMeetings: build.query<Meeting[], MeetingsRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings`,
      providesTags: [{ type: "Meetings", id: "LIST" }],
    }),
    getMeeting: build.query<Meeting, MeetingRequest>({
      query: ({ customerToken, facilityObjectId, meetingId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}`,
    }),
    patchMeeting: build.mutation<Meeting, MeetingRequest>({
      query: ({
        customerToken,
        facilityObjectId,
        meetingId,
        onlineMeetingInformation,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}`,
        method: "PATCH",
        body: {
          onlineMeetingInformation,
        },
      }),
      invalidatesTags: [{ type: "Meetings", id: "LIST" }],
    }),
    updateMeetings: build.mutation<Meeting[], MeetingStateRequest>({
      query: ({ customerToken, meetingId, facilityObjectId, state }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/setState`,
        method: "POST",
        body: { state },
      }),
      invalidatesTags: [{ type: "Meetings", id: "LIST" }],
    }),
    validateMeeting: build.query<MeetingDataIntegrity, MeetingRequest>({
      query: ({ customerToken, facilityObjectId, meetingId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/validation`,
    }),

    postAcceptInvitation: build.mutation<void, MeetingAcceptInvitationRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        attendanceType,
        voteCastingType,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/acceptInvitation`,
        method: "POST",
        body: {
          attendanceType: attendanceType,
          voteCastingType: voteCastingType,
        },
      }),
      invalidatesTags: ["Invitation"],
    }),
    postRefuseInvitation: build.mutation<void, MeetingRequest>({
      query: ({ customerToken, meetingId, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/refuseInvitation`,
        method: "POST",
      }),
      invalidatesTags: ["Invitation"],
    }),
    getMyInvitation: build.query<MyInvitation, ParticipantsRequest>({
      query: ({ customerToken, meetingId, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/myInvitation`,
      providesTags: ["Invitation"],
    }),

    getEventLog: build.query<MeetingEventLog[], MeetingRequest>({
      query: ({ customerToken, facilityObjectId, meetingId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/eventLog`,
      providesTags: [{ type: "Meetings", id: "LIST" }],
      transformResponse: (response: MeetingEventLog[]) => {
        return response.sort((a, b) =>
          moment
            .utc(a.createdAtUtc)
            .local()
            .diff(moment.utc(b.createdAtUtc).local()),
        );
      },
    }),

    getVoteInstructions: build.query<
      ParticipantVoteDirective,
      MeetingVoteInstructionsRequest
    >({
      query: ({ customerToken, facilityObjectId, meetingId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/myVoteInstructions`,
      providesTags: [{ type: "VoteInstructions", id: "LIST" }],
    }),

    getParticipantsWithVoteInstructions: build.query<
      ParticipantsWithVoteInstructions[],
      MeetingVoteInstructionsRequest
    >({
      query: ({ customerToken, facilityObjectId, meetingId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/participantsWithVoteInstructions`,
      providesTags: [{ type: "ParticipantsWithVoteInstructions", id: "LIST" }],
    }),

    addMyVoteInstructions: build.mutation<void, MeetingVoteInstructionsRequest>(
      {
        query: ({
          customerToken,
          meetingId,
          facilityObjectId,
          voteInstructions,
        }) => ({
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/myVoteInstructions`,
          method: "POST",
          body: voteInstructions,
        }),
        invalidatesTags: [{ type: "VoteInstructions", id: "LIST" }],
      },
    ),

    getMeetingPinCode: build.query<{ pinCode?: string }, MeetingRequest>({
      query: ({ customerToken, facilityObjectId, meetingId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/pinCode`,
      providesTags: [{ type: "MeetingPinCode", id: "LIST" }],
    }),

    postMeetingPinCode: build.mutation<void, MeetingPinCodeRequest>({
      query: ({ customerToken, meetingId, facilityObjectId, pinCode }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/pinCode`,
        method: "POST",
        body: {
          pinCode,
        },
      }),
      invalidatesTags: [{ type: "MeetingPinCode", id: "LIST" }],
    }),

    validateMeetingPinCode: build.mutation<void, MeetingPinCodeRequest>({
      query: ({ customerToken, meetingId, facilityObjectId, pinCode }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/pinCode/validate`,
        method: "POST",
        body: {
          pinCode,
        },
      }),
    }),

    deleteMeetingPinCode: build.mutation<void, MeetingPinCodeRequest>({
      query: ({ customerToken, meetingId, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/pinCode`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "MeetingPinCode", id: "LIST" }],
    }),
  }),
});

export const {
  useGetMeetingsQuery,
  useUpdateMeetingsMutation,
  useGetMeetingQuery,
  useLazyValidateMeetingQuery,
  useGetMyInvitationQuery,
  usePostAcceptInvitationMutation,
  usePostRefuseInvitationMutation,
  usePatchMeetingMutation,
  useGetEventLogQuery,
  useAddMyVoteInstructionsMutation,
  useGetVoteInstructionsQuery,
  useGetParticipantsWithVoteInstructionsQuery,
  useGetMeetingPinCodeQuery,
  usePostMeetingPinCodeMutation,
  useDeleteMeetingPinCodeMutation,
  useValidateMeetingPinCodeMutation,
} = meetingApi;
